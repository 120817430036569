import { apiCall } from '../../../../Middleware/API/apiRequest';
import { getTokensFromStorage } from '../../../../Utils/Auth/AuthToken';
import {
  RF_CONFIGURATION,
  RF_DEVICE_REGISTRATION,
} from '../../../../constants/apiRoutes';
import {
  getGraphqlCacheResponse,
  setGraphqlCacheResponse,
} from '../../../../Utils/Storage/IndexDB/indexDbWrappers';
/**
 * Lists RF profiles.
 * @returns {Promise} - A promise that resolves with the list of RF profiles or rejects with an error.
 */
export const listRFProfiles = async ({
  limit,
  offset,
  filter,
  sort,
  isServiceWorker,
}) => {
  const body = {
    filter,
    sort,
    pageNumber: offset + 1,
    pageSize: limit,
  };
  const { accessToken } = getTokensFromStorage();
  const response = await rfApiCall({
    url: RF_CONFIGURATION,
    method: 'POST',
    accessToken,
    headers: { 'X-Client-Sub-Module': 'Profiles' },
    body,
  });
  if (response?.isError) {
    if (isServiceWorker) {
      let cacheResponse = await getGraphqlCacheResponse(
        headers,
        isServiceWorker,
      );
      return cacheResponse;
    }
    return response;
  }
  if (isServiceWorker) {
    await setGraphqlCacheResponse(headers, response, isServiceWorker);
  }
  return { profiles: response.Payload, TotalCount: response?.TotalRecordCount };
};

/**
 * Generates QR Code for RF profiles.
 * @param {string} profileSetupId - The ID of the profile setup.
 * @param {string} srfProfilesId - The ID of the SRF profile.
 * @param devcod
 * @returns {Promise} - A promise that resolves with the generated QR code or rejects with an error.
 */
export const generateQRCode = async ({ profileSetupId, srfProfilesId }) => {
  const { accessToken } = getTokensFromStorage();
  const queryParams = new URLSearchParams({
    ProfileSetupId: profileSetupId,
    SRFProfilesId: srfProfilesId,
  });
  const routeName = `${RF_DEVICE_REGISTRATION}?${queryParams.toString()}`;
  let response = await rfApiCall({
    url: routeName,
    method: 'POST',
    accessToken,
    headers: { 'X-Client-Sub-Module': 'QRCode' },
  });
  return response;
};
/**
 * Status.
 * @param {string} profileSetupId - The ID of the profile setup.
 * @returns {Promise} - A promise that resolves with the generated QR code or rejects with an error.
 */
export const getDeviceRegistrationStatus = async ({ profileSetupId }) => {
  const { accessToken } = getTokensFromStorage();
  const queryParams = new URLSearchParams({
    ProfileSetupId: profileSetupId,
  });
  const routeName = `${RF_DEVICE_REGISTRATION}/Status?${queryParams.toString()}`;

  let response = await rfApiCall({
    url: routeName,
    method: 'POST',
    accessToken,
    headers: { 'X-Client-Sub-Module': 'Status' },
  });
  return response;
};

/**
 * RF API Call Wrapper Method.
 * @param {object} request - Contains details like url, headers, method, and data.
 * @returns {Promise} - A promise that resolves with the API response or rejects with an error.
 */
const rfApiCall = async (request) => {
  let response;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  request.headers = headers;
  try {
    response = await apiCall(request);
  } catch (e) {
    response = { isSuccess: false, message: e.message };
  }
  return response;
};

import { apiCall } from '../../../../Middleware/API/apiRequest';
import { getTokensFromStorage } from '../../../../Utils/Auth/AuthToken';
import { RFFIELDINFO } from '../../../../constants/apiRoutes';
import {
  getGraphqlCacheResponse,
  setGraphqlCacheResponse,
} from '../../../../Utils/Storage/IndexDB/indexDbWrappers';
import { doubleEncode } from '../../../../Utils/Encode/UrlHelper';
/**
 * list RFFieldInfos.
 * @returns {Promise} - A promise that resolves with the list of RFFieldInfos or rejects with an error.
 * @param props
 */
export const listRFFieldInfo = async ({
  accessToken,
  limit,
  offset,
  filter,
  sort,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const data = {
    Filters: filter,
    pageNumber: offset === 0 ? 1 : offset,
    pageSize: limit,
    sort,
  };

  const response = await apiRequest({
    url: `${RFFIELDINFO}/Filter`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  if (response?.isError) {
    if (isServiceWorker) {
      let cacheResponse = await getGraphqlCacheResponse(
        headers,
        isServiceWorker,
      );
      return cacheResponse;
    }
    return response;
  }
  if (isServiceWorker) {
    await setGraphqlCacheResponse(headers, response, isServiceWorker);
  }
  return {
    rfFieldInfo: response?.Payload,
    TotalCount: response?.TotalRecordCount,
  };
};

/**
 * Create RFFieldInfo.
 * @returns {Promise} - A promise that resolves with the creation of Field Info or rejects with an error.
 * @param data
 */
export const createRFFieldInfo = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${RFFIELDINFO}`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Update RFFieldInfo.
 * @returns {Promise} - A promise that resolves with the update of Field Info or rejects with an error.
 * @param data
 */
export const updateRFFieldInfo = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${RFFIELDINFO}/${doubleEncode(data?.ossiTcFldId)}`,
    method: 'PUT',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * delete RFFieldInfo.
 * @returns {Promise} - A promise that resolves with the update of Field Info or rejects with an error.
 * @param data
 */
export const deleteRFFieldInfo = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${RFFIELDINFO}?tenant_id=${encodeURIComponent(data?.tenantId)}&ossi_tc_fld_id=${encodeURIComponent(data?.ossiTcFldId)}&uc_ossi_form_id=${encodeURIComponent(data?.ossiTcFormId)}&uc_ossi_appl_id=${encodeURIComponent(data?.ucOssiApplId)}&uc_ossi_app_ver=${encodeURIComponent(data?.ucOssiAppVer)}&uc_ossi_srtseq=${encodeURIComponent(data?.ucOssiSrtseq)}`,
    method: 'DELETE',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * get RFFieldInfo.
 * @returns {Promise} - A promise that resolves with the RFFieldInfo or rejects with an error.
 * @param data
 */
export const getRFFieldInfo = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${RFFIELDINFO}?tenant_id=${encodeURIComponent(data?.tenantId)}&ossi_tc_fld_id=${encodeURIComponent(data?.ossiTcFldId)}&uc_ossi_form_id=${encodeURIComponent(data?.ossiTcFormId)}&uc_ossi_appl_id=${encodeURIComponent(data?.ucOssiApplId)}&uc_ossi_app_ver=${encodeURIComponent(data?.ucOssiAppVer)}&uc_ossi_srtseq=${encodeURIComponent(data?.ucOssiSrtseq)}`,
    method: 'GET',
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
  });
  return response;
};

/**
 * UserDetail Api Call Wrapper Method.
 * @param {object} api - Contains details like url headers method and data.
 * @returns {Promise} - A promise that resolves with the data of user or rejects with an error.
 */
const apiRequest = async (request) => {
  let response;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  request.headers = headers;
  try {
    response = await apiCall(request);
  } catch (e) {
    response = { isSuccess: false, message: e.message };
  }
  return response;
};

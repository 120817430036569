import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Loader,
  ErrorFallback,
  TextInput,
  DropDownInput,
  InputSwitch,
} from 'smart-react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody } from '@progress/kendo-react-layout';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import {
  buildNotification,
  GenerateNotification,
  isValidate,
  isFromValid,
} from 'smart-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  createRFFormInfo,
  updateRFFormInfo,
  getRFFormInfo,
  getRFTCFormField,
  deleteRFFormInfo,
} from '../Services/RFFormInfoService';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Field } from '@progress/kendo-react-form';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../../constants/eventDataTypes';
import TenantDropdown from '../../../Core/Components/TenantDropdown/TenantDropdown';
import CommandEditor from '../../../Core/Components/CommandEditor/CommandEditor';
import { RF_FIELD_INFO_TYPES } from '../../../../constants/applicationConstants';
import { Error } from '@progress/kendo-react-labels';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../Core/Context/AuthContext';
import {
  ValidatePermissions,
  findTenant,
  findTenantById,
} from '../../../../Utils/Auth/ValidatePermissions';
import { SplitButton, SplitButtonItem } from '@progress/kendo-react-buttons';
import {
  CREATE_MESSAGE,
  UPDATE_MESSAGE,
  ERROR_MESSAGE,
  DELETE_MESSAGE,
} from '../../../../constants/notificationMessages';
/**
 * Add New RF Field Info main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Add New RF Field Info component.
 */
const AddRFFormInfo = () => {
  const navigate = useNavigate();
  const { tenantID, getTokensFromStorage } = useAuth(); // Get tenantID using Auth Context
  /**
   * Used to handle multiple tabs
   */
  const [detailsDataModified, setDetailsDataModified] = React.useState(false);
  const [tenant, setTenant] = React.useState(findTenantById(tenantID));
  const [loading, setLoading] = React.useState(false);
  const [isEditor, setIsEditor] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [editorField, setEditorField] = React.useState({
    name: '',
    value: '',
    label: '',
  });
  const [formData, setFormData] = React.useState({
    tenantId: tenantID,
    tenantName: tenant?.TenantName,
    ucOssiAppVer: '',
    ucOssiApplId: '',
    ossiTcFormId: '',
    ucOssiSrtseq: '',
    ucOssiTtl: '',
    ucOssiType: '',
    ucOssiInfo: '',
    ucOssiInfoSqlFlg: false,
    ucOssiHeight: '',
    ucOssiDisableFlg: false,
    ucOssiRemoteCmdFlg: false,
  });
  const [validateForm, setValidateForm] = React.useState(false);
  const [tcFormFieldData, setTcFormFieldData] = React.useState([]);
  const requiredFields = [
    'tenantName',
    'ossiTcFormId',
    'ucOssiType',
    'ucOssiAppVer',
    'ucOssiApplId',
    'ucOssiSrtseq',
    'ucOssiTtl',
    'ucOssiType',
    'ucOssiInfo',
  ];
  const { state } = useLocation();

  /**
   * Get Profile Group Detail from API for edit Profile Group
   */
  React.useEffect(() => {
    if (state) {
      const { accessToken } = getTokensFromStorage();

      const { dataSet } = state;
      (async () => {
        setLoading(true);
        const response = await getRFFormInfo({
          accessToken: accessToken,
          data: dataSet,
        });
        const testData = response.Payload;
        setFormData(testData);
        setLoading(false);
      })();
      setFormData({ ...dataSet });
      setTenant(findTenant(dataSet));
      if (ValidatePermissions(dataSet)) {
        setDetailsDataModified(true);
      }
    } else {
      setDetailsDataModified(true);
    }
  }, [state]);

  /**
   * on update the tenantID update the formData
   */
  React.useEffect(() => {
    if (!state?.edit) {
      let tempTenant = findTenantById(tenantID);
      setTenant(tempTenant);
      setFormData((prevState) => ({
        ...prevState,
        tenantId: tenantID,
        tenantName: tempTenant?.TenantName,
      }));
    }
  }, [tenantID]);

  /**
   * fetch RF TC Form Field Data
   */
  const fetchData = async ({ version, formId, fieldId, fieldName }) => {
    try {
      const { accessToken } = getTokensFromStorage();
      setLoading(true);
      const response = await getRFTCFormField({
        accessToken: accessToken,
        version,
        formId,
        fieldId,
      });
      if (!fieldName) {
        setTcFormFieldData(response.Payload);
      } else if (fieldName === 'ucOssiAppVer') {
        setTcFormFieldData((prevState) => ({
          ...prevState,
          ListOssiTcFormId: response.Payload.ListOssiTcFormId,
          ListOssiTcFldId: response.Payload.ListOssiTcFldId,
        }));
        setFormData((prevState) => ({
          ...prevState,
          ossiTcFormId: '',
        }));
      } else if (fieldName === 'ossiTcFormId') {
        setTcFormFieldData((prevState) => ({
          ...prevState,
          ListOssiTcFldId: response.Payload.ListOssiTcFldId,
        }));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData({});
  }, []);

  /**
   * handle Input Change
   * @e object
   */
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  /**
   * handle dropdown Change
   * @e object
   */
  const handleDropdownChange = (e) => {
    let tempTenant = findTenantById(e.value.TenantId);
    setTenant(tempTenant);
    setFormData((prevState) => ({
      ...prevState,
      tenantId: e.value.TenantId ? e.value.TenantId : '',
      tenantName: tempTenant?.TenantName ? tempTenant?.TenantName : '',
    }));
  };

  /**
   * onSave
   * submit the rfProfile data to api
   */
  const onSave = async ({ isExit = false, isContinue = false }) => {
    setValidateForm(true);
    if (!isFromValid({ formData, requiredFields })) {
      return;
    }
    setLoading(true);
    let notificationMetaData = {};
    const data = {
      ...formData,
      ucOssiInfoSqlFlg: formData?.ucOssiInfoSqlFlg ? 1 : 0,
      ucOssiDisableFlg: formData?.ucOssiDisableFlg ? 1 : 0,
      ucOssiRemoteCmdFlg: formData?.ucOssiRemoteCmdFlg ? 1 : 0,
    };
    let response;
    let method;

    if (state?.edit) {
      method = updateRFFormInfo;
      notificationMetaData = UPDATE_MESSAGE;
    } else {
      method = createRFFormInfo;
      notificationMetaData = CREATE_MESSAGE;
      setFormData({
        tenantId: tenantID,
        tenantName: tenant?.TenantName,
        ucOssiAppVer: '',
        ucOssiApplId: '',
        ossiTcFormId: '',
        ucOssiSrtseq: '',
        ucOssiTtl: '',
        ucOssiType: '',
        ucOssiInfo: '',
        ucOssiInfoSqlFlg: false,
        ucOssiHeight: '',
        ucOssiDisableFlg: false,
        ucOssiRemoteCmdFlg: false,
      });
    }

    try {
      setValidateForm(false);
      response = await method({
        data,
        isServiceWorker: false,
      });

      if (response.Message) {
        GenerateNotification(
          buildNotification({
            title: ERROR_MESSAGE?.title,
            description: response.Message,
            style: ERROR_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      } else if (!response?.IsSuccess) {
        GenerateNotification(
          buildNotification({
            title: ERROR_MESSAGE?.title,
            description: response.StatusDesc,
            style: ERROR_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      } else {
        GenerateNotification(
          buildNotification(notificationMetaData),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );

        if (isExit) {
          navigate('/smartrf/form-infos');
        }
      }
    } catch (error) {
      GenerateNotification(
        buildNotification(ERROR_MESSAGE),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    } finally {
      setLoading(false);
    }
  };

  /**
   * onExit
   * back to rfProfiles listing screen
   */
  const onExit = () => {
    navigate('/smartrf/form-infos');
  };

  const handleSaveSplit = (e) => {
    const { itemIndex } = e;
    switch (itemIndex) {
      case 0: {
        onSave({ isExit: true });
        break;
      }
      case 1:
        onSave({ isContinue: true });
      default: {
      }
    }
  };

  /**
   * open delete rf profile dialog
   */
  const DeleteRFProfileDialog = () => {
    setVisible(true);
  };
  /**
   * delete the rf profile
   */
  const onDelete = async () => {
    setVisible(false);
    setLoading(true);
    let response = await deleteRFFormInfo({
      data: formData,
      isServiceWorker: false,
    });
    try {
      if (response.IsSuccess) {
        GenerateNotification(
          buildNotification(DELETE_MESSAGE),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
        navigate('/smartrf/form-infos');
      } else {
        GenerateNotification(
          buildNotification(ERROR_MESSAGE),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      }
    } catch (error) {
      GenerateNotification(
        buildNotification(ERROR_MESSAGE),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className='container mx-auto'>
        <React.Suspense fallback={<Loader />}>
          {loading && <Loader />}
          {visible && (
            <Dialog
              title={'Please confirm'}
              onClose={() => setVisible(!visible)}
            >
              <p className='dialog-content-msg'>
                Are you sure you want to delete this Form Info?
              </p>
              <DialogActionsBar>
                <button
                  className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base'
                  onClick={() => setVisible(!visible)}
                >
                  No
                </button>
                <button
                  className='k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md'
                  onClick={() => onDelete()}
                >
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
          <>
            <div className={`${isEditor ? '' : 'k-d-none'}`}>
              <CommandEditor
                label={'Syntax'}
                renderTypes={!detailsDataModified ? 'ReadOnly' : ''}
                validateForm={validateForm}
                formData={formData}
                setFormData={setFormData}
                detailsDataModified={detailsDataModified}
                isEditor={isEditor}
                setIsEditor={setIsEditor}
                editorField={editorField}
                setEditorField={setEditorField}
                smHeight={'100px'}
                lgHeight={'70vh'}
              />
            </div>
            <div
              className={`add-test main-page-section add-page-section ${!isEditor ? '' : 'k-d-none'}`}
            >
              <Card className='tophead'>
                <CardHeader>
                  <div className='action-buttons-container'>
                    <Link
                      className='action-icon-wrapper !k-d-block'
                      to={`/smartrf/form-infos`}
                    >
                      <FontAwesomeIcon
                        className='k-mr-2 k-pt-3'
                        icon={faArrowLeft}
                      />
                    </Link>
                    <div>
                      <p className='display-text k-mb-0'>
                        Form Info: {formData?.ossiTcFormId}
                      </p>
                    </div>
                    {state?.edit && detailsDataModified ? (
                      <div className='k-d-flex k-align-items-center k-ml-auto'>
                        <span
                          onClick={() => {
                            DeleteRFProfileDialog();
                          }}
                          className='open-editor-icon k-icon k-font-icon k-i-trash trash-icon detail-trash-icon'
                        ></span>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </CardHeader>
              </Card>
              <Card className='k-mt-3 detail-card'>
                <CardHeader>
                  <h6 className='card-title-secondary'>Details</h6>
                </CardHeader>
                <CardBody className='detail-card-body'>
                  <div>
                    <Form
                      onSubmit={onSave}
                      render={(formRenderProps) => (
                        <div className='card-content'>
                          <div className='k-d-flex single-field-row'>
                            <div
                              className={`field-wrapper ${isValidate(validateForm, formData?.tenantName) ? 'field-invalid' : 'field-valid'}`}
                            >
                              <TenantDropdown
                                textField='TenantName'
                                label='Tenant'
                                dataItemKey='TenantId'
                                className='tenant-dropdown k-align-self-end'
                                tenant={tenant}
                                setTenant={setTenant}
                                defaultItem={{
                                  TenantName: 'Select Tenant ...',
                                  disabled: true,
                                }}
                                editMode={state?.edit ? 'ReadOnly' : ''}
                                filterable={true}
                                tenantFormId='tenantId'
                                tenantFormName='tenantName'
                                setFormData={setFormData}
                                requiredField={true}
                              />
                              {isValidate(
                                validateForm,
                                formData?.tenantName,
                              ) && <Error>This field cannot be empty</Error>}
                            </div>
                          </div>
                          <div className='k-d-flex single-field-row'>
                            <div
                              className={`field-wrapper ${isValidate(validateForm, formData?.ucOssiAppVer) ? 'field-invalid' : 'field-valid'}`}
                            >
                              <DropDownInput
                                label='App Version'
                                textField='Value'
                                dataItemKey='Value'
                                name={'ucOssiAppVer'}
                                dataSet={tcFormFieldData?.ListUcOssiAppVer}
                                value={{ Value: formData?.ucOssiAppVer }}
                                defaultValue={{ Value: formData?.ucOssiAppVer }}
                                renderTypes={state?.edit ? 'ReadOnly' : ''}
                                handleChange={(e) => {
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    ucOssiAppVer: e.value?.Value,
                                  }));
                                  fetchData({
                                    version: e.value?.Value,
                                    fieldName: 'ucOssiAppVer',
                                  });
                                }}
                                filterable={true}
                                requiredField={true}
                              />
                              {isValidate(
                                validateForm,
                                formData?.ucOssiAppVer,
                              ) && <Error>This field cannot be empty</Error>}
                            </div>
                            <div
                              className={`field-wrapper ${isValidate(validateForm, formData?.ossiTcFormId) ? 'field-invalid' : 'field-valid'}`}
                            >
                              <DropDownInput
                                label='Form Id'
                                textField='Value'
                                dataItemKey='Value'
                                name={'ossiTcFormId'}
                                dataSet={tcFormFieldData?.ListOssiTcFormId}
                                value={{ Value: formData?.ossiTcFormId }}
                                defaultValue={{ Value: formData?.ossiTcFormId }}
                                renderTypes={state?.edit ? 'ReadOnly' : ''}
                                handleChange={(e) => {
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    ossiTcFormId: e.value?.Value,
                                  }));
                                }}
                                filterable={true}
                                requiredField={true}
                              />
                              {isValidate(
                                validateForm,
                                formData?.ossiTcFormId,
                              ) && <Error>This field cannot be empty</Error>}
                            </div>
                          </div>
                          <div className='k-d-flex single-field-row'>
                            <div
                              className={`field-wrapper ${isValidate(validateForm, formData?.ucOssiTtl) ? 'field-invalid' : 'field-valid'}`}
                            >
                              <Field
                                key={'ucOssiTtl'}
                                id={'ucOssiTtl'}
                                name={'ucOssiTtl'}
                                data={formData?.ucOssiTtl}
                                onChange={handleInputChange}
                                label={'Title'}
                                type={'text'}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                                requiredField={true}
                              />
                              {isValidate(
                                validateForm,
                                formData?.ucOssiTtl,
                              ) && <Error>This field cannot be empty</Error>}
                            </div>
                            <div
                              className={`field-wrapper ${isValidate(validateForm, formData?.ucOssiType) ? 'field-invalid' : 'field-valid'}`}
                            >
                              <DropDownInput
                                label='Type'
                                name={'ucOssiType'}
                                dataSet={RF_FIELD_INFO_TYPES}
                                value={formData?.ucOssiType}
                                defaultValue={formData?.ucOssiType}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                handleChange={(e) => {
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    ucOssiType: e.value,
                                  }));
                                }}
                                requiredField={true}
                              />
                              {isValidate(
                                validateForm,
                                formData?.ucOssiType,
                              ) && <Error>This field cannot be empty</Error>}
                            </div>
                          </div>
                          <div className='k-d-flex single-field-row'>
                            <div
                              className={`field-wrapper ${isValidate(validateForm, formData?.ucOssiApplId) ? 'field-invalid' : 'field-valid'}`}
                            >
                              <Field
                                key={'ucOssiApplId'}
                                id={'ucOssiApplId'}
                                name={'ucOssiApplId'}
                                data={formData?.ucOssiApplId}
                                onChange={handleInputChange}
                                label={'App Id'}
                                type={'text'}
                                component={TextInput}
                                renderTypes={state?.edit ? 'ReadOnly' : ''}
                                optional={false}
                                requiredField={true}
                              />
                              {isValidate(
                                validateForm,
                                formData?.ucOssiApplId,
                              ) && <Error>This field cannot be empty</Error>}
                            </div>
                            <div
                              className={`field-wrapper ${isValidate(validateForm, formData?.ucOssiSrtseq) ? 'field-invalid' : 'field-valid'}`}
                            >
                              <Field
                                key={'ucOssiSrtseq'}
                                id={'ucOssiSrtseq'}
                                name={'ucOssiSrtseq'}
                                data={formData?.ucOssiSrtseq}
                                onChange={handleInputChange}
                                label={'Sort Sequence'}
                                type={'number'}
                                component={TextInput}
                                renderTypes={state?.edit ? 'ReadOnly' : ''}
                                optional={false}
                                requiredField={true}
                              />
                              {isValidate(
                                validateForm,
                                formData?.ucOssiSrtseq,
                              ) && <Error>This field cannot be empty</Error>}
                            </div>
                          </div>
                          <div className='k-d-flex single-field-row'>
                            <div className={`field-wrapper`}>
                              <Field
                                key={'ucOssiHeight'}
                                id={'ucOssiHeight'}
                                name={'ucOssiHeight'}
                                data={formData?.ucOssiHeight}
                                onChange={handleInputChange}
                                label={'Height'}
                                type={'number'}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                              />
                            </div>
                          </div>
                          <div className='k-d-flex single-field-row'>
                            <div
                              className={`field-wrapper field-wrapper-100  ${isValidate(validateForm, formData?.ucOssiInfo) ? 'field-invalid' : 'field-valid'} field-wrapper-cmd`}
                            >
                              <CommandEditor
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                validateForm={validateForm}
                                formData={formData}
                                setFormData={setFormData}
                                detailsDataModified={detailsDataModified}
                                isEditor={isEditor}
                                setIsEditor={setIsEditor}
                                editorField={{
                                  name: 'ucOssiInfo',
                                  value: formData?.ucOssiInfo,
                                  label: 'Info',
                                }}
                                setEditorField={setEditorField}
                                smHeight={'100px'}
                                lgHeight={'70vh'}
                                requiredField={true}
                              />
                              {isValidate(
                                validateForm,
                                formData?.ucOssiInfo,
                              ) && <Error>This field cannot be empty</Error>}
                            </div>
                          </div>
                          <div className='single-field-row k-d-flex k-mt-4'>
                            <div className={`field-wrapper`}>
                              <Field
                                name='ucOssiDisableFlg'
                                component={InputSwitch}
                                label='Disabled'
                                onLabel='Yes'
                                offLabel='No'
                                id='ucOssiDisableFlg'
                                checked={formData?.ucOssiDisableFlg}
                                onChange={handleInputChange}
                                disabled={!detailsDataModified}
                              />
                            </div>
                            <div className={`field-wrapper`}>
                              <Field
                                name='ucOssiInfoSqlFlg'
                                component={InputSwitch}
                                label='Info Sql Flag'
                                onLabel='Yes'
                                offLabel='No'
                                id='ucOssiInfoSqlFlg'
                                checked={formData?.ucOssiInfoSqlFlg}
                                onChange={handleInputChange}
                                disabled={!detailsDataModified}
                              />
                            </div>
                            <div className={`field-wrapper`}>
                              <Field
                                name='ucOssiRemoteCmdFlg'
                                component={InputSwitch}
                                label='Remote Command Flag'
                                onLabel='Yes'
                                offLabel='No'
                                id='ucOssiRemoteCmdFlg'
                                checked={formData?.ucOssiRemoteCmdFlg}
                                onChange={handleInputChange}
                                disabled={!detailsDataModified}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </CardBody>
              </Card>
              {detailsDataModified && (
                <Card className='k-mt-3'>
                  <CardHeader>
                    <div className='k-d-flex single-field-row button-form-wrapper k-action-buttons !k-px-0'>
                      <>
                        <Button
                          icon={'cancel'}
                          onClick={() => onExit()}
                          type='button'
                        >
                          Cancel
                        </Button>
                        <SplitButton
                          text='save'
                          themeColor={'primary'}
                          onButtonClick={() => onSave({ isExit: false })}
                          onItemClick={handleSaveSplit}
                        >
                          <SplitButtonItem text='Save & Close' />
                        </SplitButton>
                      </>
                    </div>
                  </CardHeader>
                </Card>
              )}
            </div>
          </>
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default AddRFFormInfo;
